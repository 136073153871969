import Tippy from '@tippyjs/react';
import { TooltipProps } from './props';
import { cn } from '@app/utils/cn';
import 'tippy.js/animations/scale.css';

const Tooltip = ({ className, children, ...props }: TooltipProps) => {
  return <Tippy {...props} animation='scale' className={cn('tooltip', className)} >{children}</Tippy>
};

export default Tooltip;
