import { BASE_API } from '@app/constants/endpoints';
import { Workspace } from '@entities/models/workspace';
import customFetch from '@app/utils/customFetch';
import { Role } from '@entities/models/role';
import { WorkspaceMember } from '@entities/models/workspaceMember';

export const getWorkspaces = async (): Promise<Workspace[]> => {
  const rawResponse = await customFetch(`${BASE_API}/frontend/workspace`);
  return await rawResponse.json();
};

export const createNewWorkspace = async (name: string): Promise<Workspace> => {
  const rawResponse = await customFetch(`${BASE_API}/frontend/workspace`, {
    method: 'POST',
    body: JSON.stringify({ name }),
  });

  return await rawResponse.json();
};

export const getWorkspace = async (id: string): Promise<Workspace> => {
  const rawResponse = await customFetch(`${BASE_API}/frontend/workspace/${id}`);
  return await rawResponse.json();
};

export const deleteUserFromWorkspace = async (
  workspaceId: string,
  userId: string
): Promise<any> => {
  await customFetch(
    `${BASE_API}/frontend/workspace/${workspaceId}/user/${userId}`,
    {
      method: 'DELETE',
    }
  );
};

type ChangeUserWorkspaceRole = ({
  workspaceId,
  userId,
  role,
}: {
  workspaceId: string;
  userId: string;
  role: Role;
}) => Promise<any>;

export const changeUserWorkspaceRole: ChangeUserWorkspaceRole = async ({
  workspaceId,
  userId,
  role,
}): Promise<any> => {
  await customFetch(
    `${BASE_API}/frontend/workspace/${workspaceId}/user/${userId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({ role }),
    }
  );
};

type InviteToWorkspace = ({
  emails,
  role,
  workspaceId,
}: {
  emails: string[];
  role: Role;
  workspaceId: string;
}) => Promise<any>;

export const inviteToWorkspace: InviteToWorkspace = async ({ emails, role, workspaceId }) => {
  const rawResponse = await customFetch(
    `${BASE_API}/frontend/workspace/${workspaceId}/invite`,
    {
      method: 'POST',
      body: JSON.stringify({ emails, role }),
    }
  );
  return rawResponse;
};

type TransferWorkspaceOwnership = ({
  workspaceId,
  userId,
}: {
  workspaceId: string;
  userId: string;
}) => Promise<any>;

export const transferWorkspaceOwnership: TransferWorkspaceOwnership = async ({
  workspaceId,
  userId,
}) => {
  const rawResponse = await customFetch(
    `${BASE_API}/frontend/workspace/${workspaceId}/user/${userId}/transfer-ownership`,
    {
      method: 'POST',
    }
  );
  return rawResponse;
};
