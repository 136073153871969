import { useState, useEffect } from 'react';

const useEmbedded = () => {
  const [isEmbedded, setIsEmbedded] = useState(false);

  useEffect(() => {
    const checkIfEmbedded = () => {
      const urlParams = new URLSearchParams(window.location.search);
      setIsEmbedded(urlParams.get('embedded') === 'true');
    };

    checkIfEmbedded();
  }, []);

  return isEmbedded;
};

export default useEmbedded;
