export const UNIT_PERMISSION_VIEW = 'view' as const;
export const UNIT_PERMISSION_VIEW_MEMBERS = 'view_members' as const;

export const UNIT_PERMISSION_COMMENT = 'comment' as const;
export const UNIT_PERMISSION_DELETE = 'delete' as const;

export const UNIT_PERMISSION_DUPLICATE = 'duplicate' as const;
export const UNIT_PERMISSION_EDIT = 'edit' as const;

export const UNIT_PERMISSION_MANAGE_MEMBERS = 'manage_members' as const;
export const UNIT_PERMISSION_SHARE = 'share' as const;

export type Permission =
  | typeof UNIT_PERMISSION_VIEW
  | typeof UNIT_PERMISSION_VIEW_MEMBERS
  | typeof UNIT_PERMISSION_COMMENT
  | typeof UNIT_PERMISSION_DELETE
  | typeof UNIT_PERMISSION_DUPLICATE
  | typeof UNIT_PERMISSION_EDIT
  | typeof UNIT_PERMISSION_MANAGE_MEMBERS
  | typeof UNIT_PERMISSION_SHARE;
