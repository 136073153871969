import { useQuery } from '@tanstack/react-query';

import { api } from '@app/utils/api';
import { errorHandler } from '@app/utils/errorHandler';
import { WorkspaceMember } from '@entities/models/workspaceMember';

type Payload = {
  workspaceId: string;
  enabled?: boolean;
};

const getWorkspaceGuests = async (
  workspaceId: string,
): Promise<WorkspaceMember[]> => {
  const uri = `/frontend/workspace/${workspaceId}/guest`;
  const response = await api.get<WorkspaceMember[]>(uri);
  return response.data ?? [];
};

export const useGetWorkspaceGuests = ({
  workspaceId,
  enabled = true,
}: Payload) => {
  const {
    error,
    data: guests,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getWorkspaceGuests', workspaceId],
    queryFn: () => getWorkspaceGuests(workspaceId),
    enabled: enabled,
  });

  if (error) errorHandler(error);

  return {
    guests: guests ? guests : [],
    activeGuests: guests?.filter((el) => !el.user.deleted) ?? [],
    refetch,
    isPending,
  };
};
