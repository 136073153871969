import { useCallback, useEffect, useState } from 'react';
import Button from '@shared/common/Button';
import Modal from '@shared/common/Modal';
import InputBase from '@shared/common/input/InputBase';
import TagsInput from '@shared/common/input/TagsInput';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { IGetLink, getWorkspaceInviteLink } from '@app/services/share.service';
import {
  AccessTypes,
  ParticipantType,
} from '@widgets/components/ShareDoc/types';
import {
  darkToastOptions,
  errorToastOptions,
  successToastOptions,
} from '@shared/common/Toast';
import { track } from '@amplitude/analytics-browser';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@app/redux/features/userSlice';
import { inviteToWorkspace } from '@app/services/workspace.service';

const MemberInvite = ({ setModalIsOpen, modalIsOpen }: any) => {
  const [linkScope, setLinkScope] = useState<ParticipantType>('member');
  const [emails, setEmails] = useState<string[]>([]);
  const [inviteLink, setInviteLink] = useState('');
  const user = useSelector(selectCurrentUser);

  const handleCopyClick = () => {
    track('members_settings_invite_link_copied');
    navigator.clipboard.writeText(inviteLink).then(
      function () {
        toast('Link copied to clipboard', darkToastOptions);
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
        toast('Failed to copy link to clipboard', darkToastOptions);
      },
    );
  };

  const handleEmailChange = useCallback((emailsList: string[]) => {
    const emailsToUse = _.filter(emailsList, (item) => Boolean(item));

    if (_.isEmpty(emailsToUse)) {
      return;
    }

    setEmails(emailsToUse);
  }, []);

  const handleSendEmail = useCallback(async () => {
    if (!user?.workspace?.id) {
      console.error('No workspace id available');
      return;
    }

    track('members_settings_invite_sent_action');

    try {
      const result = await inviteToWorkspace({
        emails,
        role: 'member',
        workspaceId: user.workspace.id,
      });

      if (result.status !== 200) {
        throw new Error('Something went wrong');
      }
      setModalIsOpen(false);
      toast.success('Invites successfully sent!', successToastOptions);
    } catch (error) {
      track('members_settings_invite_sent_action_failed');
      toast.error('Invite sending error', errorToastOptions);
    }
  }, [user?.workspace?.id, emails]);

  useEffect(() => {
    if (!user.workspace) return;
    const body = {
      role: linkScope,
      access: 'full_access' as AccessTypes,
      subDocs: true,
    };

    async function retrieveLInk(body: IGetLink) {
      if (!user.workspace) return;
      try {
        const result = await getWorkspaceInviteLink(body, user.workspace.id);
        if (!!result) {
          setInviteLink(result.link);
        } else {
          setInviteLink('Unable retrieve link');
        }
      } catch (e) {
        console.error('Cannot reterieve link', e);
      }
    }

    retrieveLInk(body);
  }, [linkScope, user.workspace]);

  return (
    <Modal
      closeModal={() => setModalIsOpen(false)}
      modalIsOpen={modalIsOpen}
      userClassName='w-578'
    >
      <div className='text-20-16 font-medium'>Invite members</div>
      <div className='mt-10 flex items-center'>
        <div className='text-sm font-medium text-text90'>
          Invite via this link
        </div>
        {/* <InviteAccesOptions
          className='invitebyemail__options p-2'
          onApply={handleAccessApply}
          initialState={{ participant: linkScope }}
          hideAccessLevel
          hideSubDocs
        /> */}
      </div>
      <div className='mt-2 flex'>
        <InputBase
          name='inviteLink'
          disabled
          value={inviteLink}
          className='!w-100 mr-3 shrink-0	'
        />
        <Button
          label='Copy'
          styleType='small-primary'
          className='w-23'
          disabled={!inviteLink}
          onClick={handleCopyClick}
        />
      </div>
      <div className='mt-7.5 flex items-center'>
        <div className='text-sm font-medium text-text90'>Invite via email</div>
        {/* <InviteAccesOptions
          className='invitebyemail__options p-2'
          onApply={handleEmailAccessApply}
          initialState={{ participant: emailInviteScope }}
          hideAccessLevel
          hideSubDocs
        /> */}
      </div>
      <div className='mt-2 flex'>
        <TagsInput
          className='w-100 mr-3 !overflow-auto'
          placeholder='Enter email'
          onChangeTags={handleEmailChange}
        />
        <Button
          label='Send'
          styleType='small-primary'
          className='w-23'
          onClick={handleSendEmail}
          disabled={!emails.length}
        />
      </div>
      <div className='text-xs font-medium text-text40 mt-1.5'>
        Please feel free to add multiple emails, separated by commas.
      </div>
    </Modal>
  );
};

export default MemberInvite;
