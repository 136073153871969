import { Unit } from '@entities/models/unit';
import { useUnitActionList } from './useUnitActionList';
import MoreButton, { Position } from '@shared/uikit/more-button';
import { track } from '@amplitude/analytics-browser';

type UnitActionListBtnProps = {
  unit: Unit;
  isCompact?: boolean;
  position?: Position;
};

export const UnitActionListBtn: React.FC<UnitActionListBtnProps> = ({
  unit,
  isCompact = false,
  position = 'bottom',
}) => {
  const unitActionListJSX = useUnitActionList({ unit });

  if (!unitActionListJSX) return null;

  return (
    <MoreButton
      isCompact={isCompact}
      position={position}
      children={unitActionListJSX}
      onOpenCallback={() => {
        track(`${unit.type}_advanced_menu_opened`);
      }}
    />
  );
};
