import { useWorkspaceContext } from '@app/context/workspaceContext/workspaceContext';
import Loading from '@shared/common/Loading';
import WorkspaceList from './WorkspaceList';
import AuthLayout from '@pages/layouts/AuthLayout/AuthLayout.component';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const WorkspaceListContainer = () => {
  const { workspaces, workspacesLoaded, switchWorkspace } =
    useWorkspaceContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (workspacesLoaded && workspaces.length === 1) {
      switchWorkspace(workspaces[0]);
      return navigate('/home');
    }
    if (workspacesLoaded && workspaces.length === 0) {
      return navigate('/new-workspace');
    }
  }, [workspaces, workspacesLoaded]);

  if (!workspaces?.length) {
    return <Loading />;
  }

  return (
    <AuthLayout>
      <WorkspaceList />
    </AuthLayout>
  );
};

export default WorkspaceListContainer;
