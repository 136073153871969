export const successToastOptions = {
  style: {
    fontWeight: 500,
    fontSize: '14px',
  },
};

export const errorToastOptions = {
  style: {
    background: '#FFECEE',
    fontWeight: 500,
    fontSize: '14px',
  },
};

export const darkToastOptions = {
  style: {
    background: '#3e3e41',
    fontWeight: 500,
    fontSize: '14px',
    color: "#fff"
  },
};
