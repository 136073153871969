import { forwardRef } from "react";

import { cn } from "@app/utils/cn";
import { ReactComponent as Plus } from '@images/plus.svg';

import { PlusButtonProps } from "./props";
import styles from './styles.module.scss'

const PlusButton = forwardRef<HTMLButtonElement, PlusButtonProps>(({ className, ...props }, ref) => {
  return (
    <button
      {...props}
      className={cn('transition', styles['plus-button'], className)}
      ref={ref}
    >
      <Plus className={styles['plus-icon']} />
    </button>
  )
})

export default PlusButton