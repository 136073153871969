import { useQuery } from '@tanstack/react-query';

import { api } from '@app/utils/api';
import { errorHandler } from '@app/utils/errorHandler';
import { WorkspaceMember } from '@entities/models/workspaceMember';

type Payload = {
  workspaceId: string;
  enabled?: boolean;
};

const getWorkspaceMembers = async (
  workspaceId: string,
): Promise<WorkspaceMember[]> => {
  const uri = `/frontend/workspace/${workspaceId}/user`;
  const response = await api.get<WorkspaceMember[]>(uri);
  return response.data ?? [];
};

export const useGetWorkspaceMembers = ({
  workspaceId,
  enabled = true,
}: Payload) => {
  const {
    error,
    data: members,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getWorkspaceMembers', workspaceId],
    queryFn: () => getWorkspaceMembers(workspaceId),
    enabled: enabled,
  });

  if (error) errorHandler(error);

  return {
    members: members ? members : [],
    activeMembers: members?.filter((el) => !el.user.deleted) ?? [],
    refetch,
    isPending,
  };
};
