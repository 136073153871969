import { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';
import { ReactComponent as Loader } from '@images/loader.svg';
import { Link, useNavigate } from 'react-router-dom'
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { checkLink } from '@app/services/joinUnit.service';
import { track } from '@amplitude/analytics-browser';
import { toast } from 'react-hot-toast';
import { successToastOptions } from '@shared/common/Toast';
import { useWorkspaceContext } from '@app/context/workspaceContext/workspaceContext';

const INIT = 'init';
const PROCESSING = 'processing';
const SUCCESS = 'success';
const ERROR = 'error'

type JoinStatus = typeof INIT | typeof PROCESSING | typeof SUCCESS | typeof ERROR;

export function Join() {
  const { resetWorkspaces } = useWorkspaceContext()
  const {search}  = useLocation()
  let { unitId } = useParams();
  const navigate = useNavigate()
  const [status, setStatus] = useState<JoinStatus>(INIT)
  const inviteId = new URLSearchParams(search).get('inviteId')

  useEffect(() => {
    if (!inviteId || !unitId) {
      setStatus(ERROR)
      return;
    }

    setStatus(PROCESSING)
    checkLink(unitId, inviteId).then(async () => {
      await resetWorkspaces()
      track('document_open', { document_id: unitId, source: 'invitation' });
      toast.success('Invitation accepted', successToastOptions);
      setStatus(SUCCESS)
    }).catch((error) => {
      track('document open_failed', { reason: JSON.stringify(error) });
      setStatus(ERROR)
    })
  }, [])

  useEffect(() => {
    if (status === SUCCESS) {
      setTimeout(() => {
        navigate(`/workspace/${unitId}`)
      }, 5000)
    }

  }, [status])


  if (status === INIT || status === PROCESSING) {
    return (
      <div className = 'fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center' >
      <Loader className='inline w-12 h-12 text-primary animate-spin' />
      <h2 className='text-center text-xl font-medium'>Checking initation link...</h2>
      <p className='w-1/3 text-center'>
        This may take a few seconds, please don't close this page.
      </p>
    </div >
    )
  }

  if (status === SUCCESS) {
    return (
      <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center' >
        <h2 className='text-center text-xl font-medium'>All good! Redirecting you to the document...</h2>
      </div >
    )
  }

  const renderer = ({ seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      // Render a completed state
      return <Navigate to="/home" replace/>;
    } else {
      // Render a countdown
      return <span>{seconds}</span>;
    }
  };


  return (
    <div className = 'fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center' >
      <h2 className='text-center text-xl font-medium'>Provided link is invalid or you don't have permission to access the document</h2>
      <p className='w-1/3 text-center'>
        you will be redirected to the main page in <Countdown date={Date.now() + 10000} renderer={renderer}/>
      </p>
      <Link to="/home">Main page</Link>
    </div >
    )
}

