import React, { useEffect, useState } from 'react';
import { inviteMembers, searchUsers, User } from '@app/services/share.service';
import Button from '@shared/common/Button';
import { DEFAULT_STATE, InviteAccesOptions } from './InviteAccesOptions';
import { getInitials } from './MemberListItem';
import { useParams } from 'react-router-dom';
import { AccessState } from './types';
import { useClickOutside } from '@app/hooks/useClickOutside';
import { ReactComponent as Close } from '@images/close.svg';
import toast from 'react-hot-toast';
import { errorToastOptions, successToastOptions } from '@shared/common/Toast';
import { track } from '@amplitude/analytics-browser';

interface IInviteByEmail {
  onInvite?: () => void;
  onInviteError?: () => void;
  onChange?: (state: any) => void;
  hideMembership?: boolean;
  hideAccessLevel?: boolean;
  hideSubDocs?: boolean;
  unitId: string;
}

export function InviteByEmail(props: IInviteByEmail) {
  const [accessOptions, setAccessOptions] = useState(DEFAULT_STATE);
  const [selectedOptions, setSelectedOptions] = useState<User[]>([]);
  const [options, setOptions] = useState<User[]>([]);

  function resetValues() {
    setAccessOptions(DEFAULT_STATE);
    setOptions([]);
    setSelectedOptions([]);
  }

  const handleInvite = () => {
    toast.success(
      `${selectedOptions.length === 1 ? 'Invite' : 'Invites'} successfully sent!`,
      successToastOptions,
    );
  };

  const handleInviteError = () => {
    toast.error('Invite sending error', errorToastOptions);
  };

  const handleSelectChange = (newValue: User[]) => {
    if (!newValue) return;
    const newState = Array.isArray(newValue) ? newValue : [newValue];
    props.onChange &&
      props.onChange({ invites: newState, options: accessOptions });
  };

  const handleAccessApply = (data: AccessState) => {
    setAccessOptions(data);
  };

  const handleSendInvite = async () => {
    const payload = {
      emails: selectedOptions.map((el) => {
        return (el as User).email;
      }),
      access: accessOptions.access,
      role: accessOptions.participant,
    };
    try {
      await inviteMembers(payload, props.unitId).then((response) => {
        if (!response?.ok) {
          throw new Error('Invite sending error');
        }
        track('document_share_invite_sent');
        props.onInvite ? props.onInvite() : handleInvite();
      });
    } catch (e) {
      props.onInviteError ? props.onInviteError() : handleInviteError();
    }
    resetValues();
  };

  return (
    <div className='invitebyemail'>
      <div className='flex items-center invitebyemail__select'>
        <Combobox
          onChange={handleSelectChange}
          options={options}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          setOptions={setOptions}
        />
        <InviteAccesOptions
          className='invitebyemail__options p-2'
          onApply={handleAccessApply}
          isButtonLike
          hideAccessLevel={props.hideAccessLevel}
          hideMembership={props.hideMembership}
          hideSubDocs={true}
        />
      </div>
      <Button
        label='Invite'
        styleType='primary'
        className='invitebyemail__button text-sml !py-2.01 !w-24'
        onClick={handleSendInvite}
      />
    </div>
  );
}

interface ICombobox {
  onChange?: (options: User[]) => void;
  selectedOptions: User[];
  options: User[];
  setSelectedOptions: (options: User[]) => void;
  setOptions: (options: User[]) => void;
}

export function Combobox(props: ICombobox) {
  const { selectedOptions, options, setSelectedOptions, setOptions, onChange } =
    props;
  const { documentId } = useParams();
  const { ref, isVisible, setIsVisible } = useClickOutside(false);

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (options.length) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [options]);

  async function getOptions(searchString: string) {
    if (!documentId) return;
    return searchUsers(searchString, documentId)
      .then((results) => {
        return !!results ? results.filter((item) => !item.deleted) : [];
      })
      .then((data) => {
        return setOptions(data.filter((option) => !option.deleted));
      });
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setQuery(value);
    if (value.length > 2) {
      getOptions(value);
    }
  }

  function handleOptionSelect(option: User) {
    const newOptions = [...selectedOptions, option];
    setSelectedOptions(newOptions);
    setOptions(options.filter((el) => el.id !== option.id));
    setQuery('');
    if (onChange) {
      onChange(newOptions);
    }
  }

  function handleOptionDelete(option: User) {
    const newOptions = selectedOptions.filter(
      (selectedOption) => selectedOption.id !== option.id,
    );
    if (!!option.name) {
      setOptions([...options, option]);
    }
    setSelectedOptions(newOptions);
    if (onChange) {
      onChange(newOptions);
    }
  }

  function handleCreateOption(event: React.UIEvent<HTMLButtonElement>) {
    const newOption = { id: Date.now().toString(), email: query, name: '' };
    const newOptions = [...selectedOptions, newOption];
    setSelectedOptions(newOptions);
    setQuery('');
    if (onChange) {
      onChange(newOptions);
    }
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (query.length > 0 && validateEmail(query)) {
      const newOption = {
        id: Date.now().toString(),
        email: event.target.value,
        name: '',
      };
      const newOptions = [...selectedOptions, newOption];
      setSelectedOptions(newOptions);
      setQuery('');
      if (onChange) {
        onChange(newOptions);
      }
    }
  }

  const Option = ({ user }: { user: User }) => {
    return (
      <div className='member-item__inner member-item--option '>
        <svg
          role='image'
          aria-label={`${user.name}'s avatar`}
          preserveAspectRatio='xMidYMid slice'
          viewBox='0 0 120 60'
          className='member-item__image'
        >
          <image
            width='100%'
            height='100%'
            xlinkHref={user.imageSrc}
          ></image>
          <text
            x='50%'
            y='50%'
            dominantBaseline='middle'
            textAnchor='middle'
            fontSize='1.5rem'
          >
            {getInitials(user.name || user.email)}
          </text>
        </svg>
        <span className='member-item__text'>{user.name}</span>
      </div>
    );
  };

  return (
    <div
      className='combobox'
      ref={ref}
    >
      <div className='selected-options'>
        {selectedOptions.map((option) => (
          <div
            className='selected-option'
            key={option.id}
          >
            {option.email}
            <button
              className='delete-button'
              onClick={() => handleOptionDelete(option)}
            >
              <Close className='cursor-pointer' />{' '}
            </button>
          </div>
        ))}
        <input
          type='text'
          value={query}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          placeholder='Email, comma separated'
        />
      </div>
      {isVisible && (
        <ul className='options-list'>
          {options.map((option) => (
            <li
              key={option.id}
              onClick={() => handleOptionSelect(option)}
            >
              <Option user={option} />
            </li>
          ))}
          {query !== '' && (
            <li className='member-item__inner member-item--option'>
              <button onClick={handleCreateOption}>Invite {query}</button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

function validateEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function getAutocomplete() {
  return [
    {
      id: '6432f9212e23f607948bb092',
      email: 'joniware@prismatic.com',
      name: 'Levy Roman',
      deleted: true,
    },
    {
      id: '6432f921a2e05ec129f7639d',
      email: 'levyroman@prismatic.com',
      name: 'Caldwell Fernandez',
      deleted: true,
    },
    {
      id: '6432f921f0b30f3f874eb2ce',
      email: 'caldwellfernandez@prismatic.com',
      name: 'Colon Bond',
      deleted: false,
    },
    {
      id: '6432f921a399d6e293685f87',
      email: 'colonbond@prismatic.com',
      name: 'Winifred Murray',
      deleted: false,
    },
    {
      id: '6432f9217825405fb7955c67',
      email: 'winifredmurray@prismatic.com',
      name: 'Cortez Tate',
      deleted: false,
    },
    {
      id: '6432f921eabf2a4cf30bc8f9',
      email: 'corteztate@prismatic.com',
      name: 'Kidd Hyde',
      deleted: false,
    },
    {
      id: '6432f9219565fca050429638',
      email: 'kiddhyde@prismatic.com',
      name: 'Irma Weaver',
      deleted: true,
    },
  ];
}
