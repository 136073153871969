import Modal from '@shared/common/Modal';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '@shared/common/Button';
import { ReactComponent as Link } from '@images/link.svg';
import { InviteAccesOptions } from './InviteAccesOptions';
import { WorkspaceMembersForUnit } from '@entities/models/workspace';
import { MemberListItem } from './MemberListItem';
import { ShareLinkRoleOptionMap, SharingSelector } from './SharingSelector';
import { InviteByEmail } from './InviteByEmail';
import { ReactComponent as World } from '@images/world.svg';

import {
  getMembersForUnit as getDocumentMembers,
  getInviteLink,
  IGetLink,
  modifyUserAccessLevel,
} from '@app/services/share.service';
import './sharedoc.styles.css';
import { AccessState, AccessTypes, ParticipantType } from './types';
import {
  darkToastOptions,
  successToastOptions,
  errorToastOptions,
} from '@shared/common/Toast';
import { track } from '@amplitude/analytics-browser';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModalState,
  updateShareModalState,
} from '@app/redux/features/modalsSlice';

const shareViaLinkRoleOptionsMap: ShareLinkRoleOptionMap = {
  guest: {
    title: 'Anyone with the link',
    description:
      'Doc via this link will be available to anyone on the Internet',
    icon: World,
    disabled: false,
    comingSoon: false,
  },
};

export function ShareDoc() {
  const dispatch = useDispatch();
  const [members, setMembers] = useState<WorkspaceMembersForUnit>([]);
  const [linkScope, setLinkScope] = useState<ParticipantType>('guest');
  const [linkAccess, setLinkAccess] = useState<
    Omit<AccessState, 'participant'>
  >({ access: 'view', subDocs: false });
  const [inviteLink, setInviteLink] = useState('');

  const { isOpen, title, unitId } = useSelector(getModalState).shareModalState;

  useEffect(() => {
    if (isOpen) {
      track('document_share_popup', { option: 'share' });
    }
  }, [isOpen]);

  useEffect(() => {
    async function getMembers() {
      if (!unitId) return;
      const res = await getDocumentMembers(unitId);
      setMembers(res ?? []);
    }
    getMembers();
  }, [unitId, isOpen]);

  useEffect(() => {
    const body = {
      role: linkScope,
      access: linkAccess.access,
      subDocs: linkAccess.subDocs,
    };

    async function retrieveLInk(body: IGetLink) {
      const result = await getInviteLink(body, unitId);
      if (!!result) {
        setInviteLink(result.link);
      }
    }

    retrieveLInk(body);
  }, [linkScope, linkAccess, unitId, isOpen]);

  const handleEditMember = (access: AccessTypes, id: string) => {
    if (!unitId) return;
    try {
      modifyUserAccessLevel(access, id, unitId);
      toast.success('Access level successfully changed!', successToastOptions);
    } catch (e) {
      toast.error('Unable change access level', errorToastOptions);
    }
  };

  /**
   * Sharing link handlers start
   * TODO: Refactor please. Move to separate module
   */
  const onChangeSharingScope = (option: ParticipantType) => {
    setLinkScope(option);
  };
  const [initialAccessType, setInitialAccessType] =
    useState<AccessTypes>('view');

  const onChangeLinkAccessSelector = (option: AccessState) => {
    setLinkAccess({ access: option.access, subDocs: option.subDocs });
    setInitialAccessType(option.access);
  };

  const handleCopyClick = () => {
    track('document_share_link_copied', { option: 'share' });
    navigator.clipboard.writeText(inviteLink).then(
      function () {
        toast('Link copied to clipboard', darkToastOptions);
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
        toast('Failed to copy link to clipboard', darkToastOptions);
      },
    );
  };
  /**
   * Sharing link handlers end
   */

  const handleClose = () => {
    track('document_share_close');
    dispatch(
      updateShareModalState({
        isOpen: false,
        title: title,
        unitId: '',
      }),
    );
  };

  return (
    <Modal
      title={title ? title : 'Share doc'}
      closeModal={handleClose}
      modalIsOpen={isOpen}
      wrapChildren={false}
      userClassName='w-638 !p-10 !animate-none'
      titleClassName='!text-20-16 !font-medium'
      closeBtnClassName='absolute top-6 right-6'
    >
      <div
        className='flex flex-col full-width mt-6 z-0'
        contentEditable={false}
      >
        <InviteByEmail unitId={unitId} />
        <ul>
          {members?.map((member) => (
            <li key={member.user.id || member.user.name}>
              <MemberListItem
                onChange={handleEditMember}
                imageSrc={member.user.image}
                name={member.user.name}
                email={member.user.email}
                memberId={member.user.id}
                role={member.role}
                access={member.access}
              />
            </li>
          ))}
        </ul>
        <div className='width-full border  border-solid border-text10 my-5' />
        <div className='flex space-between width-full z-0'>
          <SharingSelector
            onChange={onChangeSharingScope}
            selectorName='workspace'
            isButtonLike
            customOptionsMap={shareViaLinkRoleOptionsMap}
          />
          <InviteAccesOptions
            className='invite_by_link_access__options'
            onApply={onChangeLinkAccessSelector}
            hideMembership
            hideSubDocs
            hasFullAccessLevel={false}
            initialAccessType={initialAccessType}
          />
          <Button
            icon={<Link className='mr-2 [&>path]:stroke-primaryHover' />}
            label='Copy'
            styleType='input-text'
            className='!text-primaryHover ml-auto font-normal'
            onClick={handleCopyClick}
            disabled={!inviteLink}
          />
        </div>
      </div>
    </Modal>
  );
}
